import React, { useEffect, useRef, useState } from 'react';
import './styles/App.scss';
import { gsap, ScrollTrigger } from './gsap';
import Header from './components/common-header/Header';
import Footer from './components/common-footer/Footer';
import Hero from './components/hero/Hero';
import Nav from './components/nav/Nav';
import MobileMenu from './components/mobileMenu/MobileMenu';
import Episode from './components/episode/Episode';
import Carousel from './components/carousel/Carousel';
import Cta from './components/cta/Cta';

function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [videoSrc, setVideoSrc] = useState(); //for resize observer
  const [darkmode, setDarkmode] = useState(false);
  const appRef = useRef(); //for detecting scroll direction for nav bar
  const navRef = useRef(); //for fixed position when scrolled up
  const headerRef = useRef(); //for positioning mobile menubar
  const mobileMenuRef = useRef();
  const carouselNsRef = useRef();
  const carouselOnRef = useRef();
  const carouselBcRef = useRef();
  const nsRef = useRef();
  const onRef = useRef();
  const bcRef = useRef();
  const wocRef = useRef();
  const ctaRef = useRef();
  const darkmodes = useRef();
  darkmodes.current = [carouselNsRef, carouselOnRef, carouselBcRef, ctaRef];

  //show/hide mobile menu
  const positionMobileMenu = () => {
    menuOpen ? setMenuOpen(false) : setMenuOpen(true);
  };

  useEffect(() => {
    const tl = gsap.timeline();
    menuOpen
      ? tl.to(mobileMenuRef.current, { x: 0, duration: 0.3 })
      : tl.to(mobileMenuRef.current, { x: '100%', duration: 0.3 });
  }, [menuOpen]);

  //keeps mobile menu below the common header
  useEffect(() => {
    const header = headerRef.current;
    const menuStyle = mobileMenuRef.current.style;
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry['isIntersecting'] === false) {
            menuStyle.position = 'fixed';
            menuStyle.top = 0;
          } else {
            menuStyle.position = 'absolute';
            menuStyle.top = '64px';
          }
        });
      },
      { threshold: [0] }
    );
    observer.observe(header);
    return () => {
      observer.unobserve(header);
    };
  }, []);

  //hides nav bar on scroll down & shows on scroll up
  useEffect(() => {
    ScrollTrigger.create({
      trigger: appRef.current,
      start: 'top -20%',
      pinSpacer: false,
      onUpdate: (self) => {
        if (self.direction === 1) {
          gsap.to(navRef.current, { y: '-135px', duration: 0.5, ease: 'linear' });
        } else if (self.direction === -1) {
          gsap.to(navRef.current, { y: '0', duration: 0.5, ease: 'linear' });
        }
      }
    });
  }, []);

  useEffect(() => {
    darkmodes.current.forEach((dark) => {
      ScrollTrigger.create({
        trigger: dark.current,
        start: 'top 10%',
        end: 'bottom 10%',
        onEnter: () => {
          setDarkmode(true);
        },
        onEnterBack: () => {
          setDarkmode(true);
        },
        onLeave: () => {
          setDarkmode(false);
        },
        onLeaveBack: () => {
          setDarkmode(false);
        }
      });
    });
  }, []);

  useEffect(() => {
    const header = headerRef.current;
    const nav = navRef.current.style;
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry['isIntersecting'] === false) {
            nav.position = 'fixed';
            nav.top = 0;
          } else {
            nav.position = 'absolute';
            nav.top = '64px';
          }
        });
      },
      { threshold: [0] }
    );
    observer.observe(header);
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const width = entries[0].contentRect.width;

      if (width !== screenWidth) {
        setScreenWidth(width);
        if (width > 767) {
          setVideoSrc('large');
        } else if (width > 480 && width < 768) {
          setVideoSrc('medium');
        } else if (width < 481) {
          setVideoSrc('small');
        }
      }
    });
    resizeObserver.observe(appRef.current);
  }, [screenWidth]);

  useEffect(() => {
    const width = window.innerWidth;
    if (width > 767) {
      setVideoSrc('large');
    } else if (width > 480 && width < 768) {
      setVideoSrc('medium');
    } else if (width < 481) {
      setVideoSrc('small');
    }
  }, []);

  return (
    <main className="App" ref={appRef}>
      <div className="skip">
        <a className="skip__link" href="#hero">
          skip to content
        </a>
      </div>
      <Header headerRef={headerRef} />

      <MobileMenu mobileMenuRef={mobileMenuRef} positionMobileMenu={positionMobileMenu} />

      <Nav
        videoSrc={videoSrc}
        positionMobileMenu={positionMobileMenu}
        navRef={navRef}
        darkmode={darkmode}
      />

      <Hero videoSrc={videoSrc} navRef={navRef} />
      <Episode videoSrc={videoSrc} dataIndex="0" episodeRef={nsRef} />
      <Carousel videoSrc={videoSrc} dataIndex="0" carouselRef={carouselNsRef} />
      <Episode videoSrc={videoSrc} dataIndex="1" episodeRef={onRef} />
      <Carousel videoSrc={videoSrc} dataIndex="1" carouselRef={carouselOnRef} />
      <Episode videoSrc={videoSrc} dataIndex="2" episodeRef={bcRef} />
      <Carousel videoSrc={videoSrc} dataIndex="2" carouselRef={carouselBcRef} />
      <Episode videoSrc={videoSrc} dataIndex="3" episodeRef={wocRef} />
      <Cta ctaRef={ctaRef} />
      <Footer />
    </main>
  );
}

export default App;
