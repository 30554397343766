import React, { useEffect, useRef } from 'react';
import './hero.scss';
import { gsap } from 'gsap';
import mapD from '../../assets/hero/mapD.png';
import mapM from '../../assets/hero/mapM.png';

function Hero({ videoSrc, navRef }) {
  const titleRef = useRef();
  const copy1Ref = useRef();
  const copy2Ref = useRef();
  const scrollDRef = useRef();
  const scrollMRef = useRef();
  const mapRef = useRef();

  useEffect(() => {
    const words = gsap.utils.selector(titleRef);
    gsap.set(
      [
        navRef.current,
        copy1Ref.current,
        copy2Ref.current,
        scrollDRef.current,
        scrollMRef.current,
        mapRef.current
      ],
      { opacity: 0 }
    );
    const tl = gsap.timeline();
    tl.to(mapRef.current, { opacity: 1, duration: 0.7, delay: 0.5 })
      .to(
        words('.words'),
        {
          opacity: 1,
          scale: 1,
          duration: 0.9,
          stagger: 0.3
        },
        '<'
      )
      .to(copy1Ref.current, { opacity: 1, duration: 0.7 }, '-=0.5')
      .to(copy2Ref.current, { opacity: 1, duration: 0.7 }, '<')
      .to(scrollDRef.current, { opacity: 1, duration: 0.7 }, '-=0.5')
      .to(scrollMRef.current, { opacity: 1, duration: 0.7 }, '<')
      .to(navRef.current, { opacity: 1, duration: 0.7 }, '<');
  }, [navRef]);

  return (
    <section className="hero" id="hero">
      <img className="hero__map" src={videoSrc === 'large' ? mapD : mapM} alt="" ref={mapRef} />
      {/* <img src={screenWidth > 768 ? mapTopD : mapTopM} alt="" className="hero__svg" /> */}
      {videoSrc === 'large' ? (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          className="hero__svg"
          viewBox="0 0 1170.6 895.5"
          aria-hidden="true"
          focusable="false"
        >
          <a href="#novaScotia" aria-label="Jump to Nova Scotia">
            <g className="hero__svg__province hero__svg__province-novaScotia">
              <title>Nova Scotia</title>
              <polygon class="svg__fill" points="893.1,535.8 893.3,537.5 891.5,537.3 	" />
              <polygon
                class="svg__fill"
                points="889,542.9 889.9,539.7 886.9,537.8 884.4,539.5 881.3,539.3 879.2,537.8 877.7,541.6 876.3,543.6 
          875.9,544.8 874.3,545.1 872.6,546.4 872.4,544.4 867.7,546.2 866.4,547.5 862.4,547.2 860.4,548.7 857.8,548 855.5,548 
          854.5,550.4 853.9,552.8 851.7,560.9 850.9,564.5 852.6,564.5 853.5,562.9 857.2,560.8 860.3,558.8 864.3,556.8 868.2,555.2 
          863.4,559.8 861.3,562.7 862.5,565.9 860.5,565 858.6,564.6 856.4,562.4 854.7,566.5 852.5,570 848.8,577 847,581.4 849.1,578.9 
          846.1,583.3 844.2,588.5 846.4,584.5 845.8,593.7 850.7,600.2 852.2,598.4 855.3,601.3 856.7,601.5 857.5,600.8 858.3,600.7 
          859,600.6 859.2,600.2 860.3,599.3 860.1,595.7 862.1,594 863.4,590.3 864.2,586.7 864.5,584.3 865.3,582.8 865.5,581.1 
          865.5,580.2 866.1,580 864.9,577.3 865.7,573.8 867.2,575.1 867.1,571.7 871.7,572.7 871.2,568.5 874.9,567.4 875.9,565.3 
          877.3,564.4 882.7,557.6 887.8,549.9 890.2,546.9 892.3,543.7 894.1,542.5 893.5,540.9 	"
              />
              <polygon
                class="svg__fill"
                points="899.4,521.6 899.8,524.4 899,528.2 893.2,534.8 889.4,538 885.7,536.3 883,526.9 882.6,516.7 
          885.5,508 888.9,512.8 890.3,519.1 891.3,522.2 890.4,525.4 887.7,530.6 889.9,528.4 889,531.3 890.4,534.5 891.9,532.5 
          893.5,531.1 894.3,526 891.6,527.9 892.2,525.4 891.7,524.1 892.3,520.1 894.7,520.6 	"
              />
            </g>
          </a>
          <a href="#ontario" aria-label="Jump to Ontario">
            <g className="hero__svg__province hero__svg__province-ontario">
              <title>Ontario</title>
              <polygon
                class="svg__fill"
                points="735.7,614.8 711.4,625.5 699.6,619.6 678.6,616.8 660,600.4 645.6,532.1 603.3,500.8 591.1,461.4 
          558.3,462.1 514.4,443.9 513.7,444.3 455.6,528.5 459.4,587.7 467.5,605.7 533.5,613.2 547.5,591.9 570.1,592.3 582.4,605.6 
          594.1,605.8 609.1,628.8 653.4,629.8 674.5,648.4 671.1,653.8 653.7,653.7 653.4,682.3 643.8,711.3 692.7,680.3 689.6,674.1 
          680.3,676.5 685.8,664.6 719.2,646.8 734.4,624.1 743.9,620.1 743.4,619 	"
              />
            </g>
          </a>
          <a href="#britishColumbia" aria-label="Jump to British Columbia">
            <g className="hero__svg__province hero__svg__province-britishColumbia">
              <title>British Columbia</title>
              <g>
                <polygon
                  class="svg__fill"
                  points="60.8,505.1 75.8,540.6 99.4,568.4 102.7,567.9 102.7,557.6 93.2,543.9 87,522.4 68,505.6 		"
                />
                <polygon
                  class="svg__fill"
                  points="39.4,421.6 36.2,432.8 36.8,447.7 41.6,468.5 43.7,444.2 39.4,441.2 44.5,438.3 52.1,428.2 		"
                />
                <polygon
                  class="svg__fill"
                  points="215.2,561.8 169.5,481.6 206.2,363.3 124,337 47.7,292.7 45.7,318 53.1,317.2 58.8,313.7 71,314.4 
            72.4,383.8 82.6,399.2 62.9,441.3 74.9,459.9 71.1,489.5 70.9,499.5 75.6,506.9 95.4,522 113.9,559.6 218.6,586.3 210.6,576.5 		
            "
                />
              </g>
            </g>
          </a>
        </svg>
      ) : (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 903.6 1041.5"
          className="hero__svg"
          aria-hidden="true"
          focusable="false"
        >
          <a href="#novaScotia" aria-label="Jump to Nova Scotia">
            <g className="hero__svg__province hero__svg__province-novaScotia">
              <title>Nova Scotia</title>
              <polygon class="svg_0--mobile" points="880.1,581.8 880.3,583.5 878.5,583.3 	" />
              <g>
                <polygon
                  class="svg__fill"
                  points="876,588.9 876.9,585.7 873.9,583.8 871.4,585.5 868.3,585.3 866.2,583.8 864.7,587.6 863.3,589.6 
			862.9,590.8 861.3,591.1 859.6,592.4 859.4,590.4 854.7,592.2 853.4,593.5 849.4,593.2 847.4,594.7 844.8,594 842.5,594 
			841.5,596.4 840.9,598.8 838.7,606.9 837.9,610.5 839.6,610.5 840.5,608.9 844.2,606.8 847.3,604.8 851.3,602.8 855.2,601.2 
			850.4,605.8 848.3,608.7 849.5,611.9 847.5,611 845.6,610.6 843.4,608.4 841.7,612.5 839.5,616 835.8,623 834,627.4 836.1,624.9 
			833.1,629.3 831.2,634.5 833.4,630.5 832.8,639.7 837.7,646.2 839.2,644.4 842.3,647.3 843.7,647.5 844.5,646.8 845.3,646.7 
			846,646.6 846.2,646.2 847.3,645.3 847.1,641.7 849.1,640 850.4,636.3 851.2,632.7 851.5,630.3 852.3,628.8 852.5,627.1 
			852.5,626.2 853.1,626 851.9,623.3 852.7,619.8 854.2,621.1 854.1,617.7 858.7,618.7 858.2,614.5 861.9,613.4 862.9,611.3 
			864.3,610.4 869.7,603.6 874.8,595.9 877.2,592.9 879.3,589.7 881.1,588.5 880.5,586.9 		"
                />
                <polygon
                  class="svg__fill"
                  points="886.4,567.6 886.8,570.4 886,574.2 880.2,580.8 876.4,584 872.7,582.3 870,572.9 869.6,562.7 
			872.5,554 875.9,558.8 877.3,565.1 878.3,568.2 877.4,571.4 874.7,576.6 876.9,574.4 876,577.3 877.4,580.5 878.9,578.5 
			880.5,577.1 881.3,572 878.6,573.9 879.2,571.4 878.7,570.1 879.3,566.1 881.7,566.6 		"
                />
              </g>
            </g>
          </a>
          <a href="#ontario" aria-label="Jump to Ontario">
            <g className="hero__svg__province hero__svg__province-ontario">
              <title>Ontario</title>
              <polygon
                class="svg__fill"
                points="722.7,660.8 698.4,671.5 686.6,665.6 665.6,662.8 647,646.4 632.6,578.1 590.3,546.8 578.1,507.4 
		545.3,508.1 501.4,489.9 500.7,490.3 442.6,574.5 446.4,633.7 454.5,651.7 520.5,659.2 534.5,637.9 557.1,638.3 569.4,651.6 
		581.1,651.8 596.1,674.8 640.4,675.8 661.5,694.4 658.1,699.8 640.7,699.7 640.4,728.3 630.8,757.3 679.7,726.3 676.6,720.1 
		667.3,722.5 672.8,710.6 706.2,692.8 721.4,670.1 730.9,666.1 730.4,665 	"
              />
            </g>
          </a>
          <a href="#britishColumbia" aria-label="Jump to British Columbia">
            <g className="hero__svg__province hero__svg__province-britishColumbia">
              <title>British Columbia</title>
              <polygon
                class="svg__fill"
                points="47.8,551.1 62.8,586.6 86.4,614.4 89.7,613.9 89.7,603.6 80.2,589.9 74,568.4 55,551.6 	"
              />
              <polygon
                class="svg__fill"
                points="26.4,467.6 23.2,478.8 23.8,493.7 28.6,514.5 30.7,490.2 26.4,487.2 31.5,484.3 39.1,474.2 	"
              />
              <polygon
                class="svg__fill"
                points="202.2,607.8 156.5,527.6 193.2,409.3 111,383 34.7,338.7 32.7,364 40.1,363.2 45.8,359.7 58,360.4 
		59.4,429.8 69.6,445.2 49.9,487.3 61.9,505.9 58.1,535.5 57.9,545.5 62.6,552.9 82.4,568 100.9,605.6 205.6,632.3 197.6,622.5 	"
              />
            </g>
          </a>
        </svg>
      )}
      <div className="hero__content">
        <h1 className="hero__content-title" ref={titleRef}>
          <span className="words">This</span> <span className="words">is</span>{' '}
          <span className="words">Canada</span>
        </h1>
        <p className="copy" ref={copy1Ref}>
          Canada is a country of wide open spaces, and wide open hearts. With its incredible
          diversity of cultures, vibrant cities, unique activities, foods, and natural beauty,
          Canada invites visitors to see life from a whole new point of view.
        </p>
        <p className="copy" ref={copy2Ref}>
          Follow three travelers as they embark on a life-changing trip up north, and be inspired to
          find your own space – for renewal, reconnection, and revelation – in this wide and
          embracing country.
        </p>
        <div className="hero__scroll hero__scroll-mobile" ref={scrollMRef}>
          <p className="hero__scroll__cap">SCROLL DOWN</p>
          <svg
            className="hero__scroll__mouse"
            width="31"
            height="45"
            viewBox="0 0 31 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
          >
            <path
              d="M16.8402 45H14.1598C6.63402 45 0.5 38.866 0.5 31.3402V13.6598C0.5 6.13402 6.63402 0 14.1598 0H16.8402C24.366 0 30.5 6.13402 30.5 13.6598V31.3402C30.5 38.866 24.366 45 16.8402 45ZM14.1598 2.57732C8.07732 2.57732 3.07732 7.52577 3.07732 13.6598V31.3402C3.07732 37.4742 8.07732 42.4227 14.1598 42.4227H16.8402C22.9742 42.4227 27.9227 37.4227 27.9227 31.3402V13.6598C27.9227 7.52577 22.9227 2.57732 16.8402 2.57732H14.1598Z"
              fill="white"
            />
            <path
              d="M21.8916 23.8661C21.4277 23.3506 20.603 23.3506 20.0875 23.8661L16.7885 27.4743V14.3816C16.7885 13.6599 16.2215 13.0929 15.4999 13.0929C14.7782 13.0929 14.2112 13.6599 14.2112 14.3816V27.5259L10.9122 23.9176C10.4483 23.4022 9.62357 23.4022 9.10811 23.9176C8.69574 24.3816 8.69574 25.1032 9.10811 25.5671L14.572 31.598C15.0359 32.1135 15.8607 32.1135 16.3761 31.598L21.8916 25.5671C22.304 25.0517 22.304 24.33 21.8916 23.8661Z"
              fill="white"
              className="hero__scroll__mouse__arrow"
            />
          </svg>
        </div>
      </div>
      <div className="hero__scroll hero__scroll-desktop" ref={scrollDRef}>
        <p className="hero__scroll__cap">SCROLL DOWN</p>
        <svg
          className="hero__scroll__mouse"
          width="31"
          height="45"
          viewBox="0 0 31 45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          focusable="false"
        >
          <path
            d="M16.8402 45H14.1598C6.63402 45 0.5 38.866 0.5 31.3402V13.6598C0.5 6.13402 6.63402 0 14.1598 0H16.8402C24.366 0 30.5 6.13402 30.5 13.6598V31.3402C30.5 38.866 24.366 45 16.8402 45ZM14.1598 2.57732C8.07732 2.57732 3.07732 7.52577 3.07732 13.6598V31.3402C3.07732 37.4742 8.07732 42.4227 14.1598 42.4227H16.8402C22.9742 42.4227 27.9227 37.4227 27.9227 31.3402V13.6598C27.9227 7.52577 22.9227 2.57732 16.8402 2.57732H14.1598Z"
            fill="white"
          />
          <path
            d="M21.8916 23.8661C21.4277 23.3506 20.603 23.3506 20.0875 23.8661L16.7885 27.4743V14.3816C16.7885 13.6599 16.2215 13.0929 15.4999 13.0929C14.7782 13.0929 14.2112 13.6599 14.2112 14.3816V27.5259L10.9122 23.9176C10.4483 23.4022 9.62357 23.4022 9.10811 23.9176C8.69574 24.3816 8.69574 25.1032 9.10811 25.5671L14.572 31.598C15.0359 32.1135 15.8607 32.1135 16.3761 31.598L21.8916 25.5671C22.304 25.0517 22.304 24.33 21.8916 23.8661Z"
            fill="white"
            className="hero__scroll__mouse__arrow"
          />
        </svg>
      </div>
    </section>
  );
}

export default Hero;
