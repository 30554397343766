import React, { useEffect, Fragment, useRef } from 'react';
import './episode.scss';
import play from '../../assets/play.svg';
import { episodeData } from './episodeData';
function Episode({ videoSrc, dataIndex, episodeRef }) {
  const videoRef1 = useRef();
  const videoRef2 = useRef();
  const videoRef3 = useRef();
  const videoRefs = useRef();
  videoRefs.current = [videoRef1, videoRef2, videoRef3];
  useEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0.3) {
            episodeRef.current.style.opacity = '1';
          }
        });
      },
      { threshold: [0, 0.3] }
    );
    observer.observe(episodeRef.current);
  }, [episodeRef]);

  useEffect(() => {
    if (videoSrc && dataIndex < 3) {
      videoRefs.current[dataIndex].current.load();
    }
  }, [videoSrc, dataIndex]);

  return (
    <section className={`episode episode--${dataIndex}`} id={episodeData[dataIndex].id}>
      {dataIndex < 3 && (
        <>
          <video
            autoPlay
            muted
            playsInline
            loop
            className={`episode__videoloop episode__videoloop--${dataIndex}`}
            ref={videoRefs.current[dataIndex]}
          >
            {videoSrc === 'small' && (
              <source src={episodeData[dataIndex].videoM} type="video/mp4" />
            )}
            {videoSrc === 'medium' && (
              <source src={episodeData[dataIndex].videoT} type="video/mp4" />
            )}
            {videoSrc === 'large' && (
              <source src={episodeData[dataIndex].videoD} type="video/mp4" />
            )}
            Your browser does not support HTML5 video.
          </video>

          <div className={`episode__overlay  episode__overlay--${dataIndex}`} />
        </>
      )}

      {dataIndex === '3' ? (
        <div className="episode-contents episode-contents--3" ref={episodeRef}>
          <h2 className="videoTitle">{episodeData[dataIndex].title}</h2>
          <p className="copy">{episodeData[dataIndex].copy}</p>
          <div className="carouselButton">
            <a
              href={episodeData[dataIndex].link}
              rel="noreferrer"
              target="_blanc"
              aria-label={episodeData[dataIndex].label}
            >
              {episodeData[dataIndex].button}
            </a>
          </div>
        </div>
      ) : (
        <div className="episode-contents" ref={episodeRef}>
          <div className="episode__video">
            <p className="videoHead">I Want to Explore...</p>
            <h2 className="videoTitle">{episodeData[dataIndex].title}</h2>
            {episodeData[dataIndex].play ? (
              <Fragment>
                <a
                  href={episodeData[dataIndex].link}
                  target="_blank"
                  rel="noreferrer"
                  aria-label={episodeData[dataIndex].label}
                  className="episode__button"
                >
                  <img
                    src={play}
                    alt=""
                    aria-hidden="true"
                    focusable="false"
                    className="episode__button-img"
                  />
                </a>
                <p className="play">Play Episode {episodeData[dataIndex].episode}</p>
              </Fragment>
            ) : (
              <h3 className="videoSubhead">episode {episodeData[dataIndex].episode} coming soon</h3>
            )}
          </div>
          <div className="episode__body">
            <img className="episode__body__map" src={episodeData[dataIndex].map} alt="" />
            <div
              className={
                episodeData[dataIndex].episode === 3
                  ? 'episode__body__traveler episode__body__traveler--bc'
                  : 'episode__body__traveler'
              }
            >
              <img
                className="episode__body__traveler__img"
                src={episodeData[dataIndex].photo}
                alt=""
                aria-hidden="true"
                focusable="false"
              />
              <h3 className="bodyHeadline">Meet {episodeData[dataIndex].name}</h3>
              <p className="copy">{episodeData[dataIndex].copy}</p>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default Episode;
