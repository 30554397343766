import React from 'react';
import './mobileMenu.scss';
import logo from '../../assets/logo.svg';
import { navItems } from '../../navItems';
import exit from '../../assets/exit.svg';
import arrow from '../../assets/arrow_nav.svg';

function MobileMenu({ positionMobileMenu, mobileMenuRef }) {
  return (
    <nav className="mobileMenu" ref={mobileMenuRef} aria-label="section navigation">
      <div className="mobileMenu__contents">
        <button
          className="mobileMenu__contents__close"
          aria-label="Close menu"
          onClick={positionMobileMenu}
        >
          <img
            src={exit}
            aria-hidden="true"
            focusable="false"
            className="mobileMenu__contents__close-img"
            alt=""
          />
        </button>
        <ul>
          {navItems.map((item, i) => {
            return (
              <li className="mobileMenu__contents__item" key={i}>
                <a
                  className="mobileMenu__contents__item__link"
                  href={`#${item.href}`}
                  onClick={positionMobileMenu}
                >
                  {item.copy}
                  {item.copy2 ? (
                    ''
                  ) : (
                    <span>
                      <img src={arrow} className="mobileMenu__arrow" alt="" />
                    </span>
                  )}
                </a>
                <p>{item.copy2 ? 'COMING SOON' : ''}</p>
                <div className="mobileMenu__contents__item__bar" />
              </li>
            );
          })}
        </ul>
        <a
          className="mobileMenu__contents__logo"
          href="https://travel.destinationcanada.com/"
          target="_blanc"
          aria-label="Visit Destination Canada Homepage"
        >
          <img src={logo} alt="" className="mobileMenu__contents__logo-img" />
        </a>
      </div>
    </nav>
  );
}

export default MobileMenu;
