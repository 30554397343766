import React from 'react';
import './Header.scss';

function Header({ clientName, headerRef }) {
  //update below to your client's name
  const updatedName = clientName || 'Destination Canada';
  return (
    <div ref={headerRef}>
      <nav className="header header--black" aria-label="Back to CNN">
        <a className="header--black__logo" href="https://www.cnn.com/">
          <img
            src="https://i.cdn.turner.com/cnn/.element/img/4.0/logos/cnn_logo_badge.png"
            alt="CNN logo"
            className="header--black__logo-img"
          />
        </a>
        <a className="header--black__textArea" href="https://www.cnn.com/">
          <svg
            width="8"
            height="16"
            viewBox="0 0 8 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.82383 0.775539C8.05822 1.00993 8.05822 1.39081 7.82383 1.6252L1.44897 8.00005L7.82383 14.3749C8.0323 14.5834 8.05596 14.9068 7.89369 15.1412L7.82383 15.2246C7.58943 15.4589 7.20855 15.4589 6.97416 15.2246L0.174667 8.42506C0.0957847 8.34731 0.0439501 8.25265 0.0180297 8.15236L0 8.05094V7.94839C0.0123954 7.81204 0.0698676 7.67907 0.174667 7.57539L6.97416 0.775891C7.20855 0.5415 7.58943 0.5415 7.82383 0.775891V0.775539Z"
              fill="#9D9D9D"
            />
          </svg>
          <span className="header--black__textArea-text">BACK TO CNN</span>
        </a>
      </nav>
      <div className="header header--gold">
        <p className="header--gold__text">{`Content by ${updatedName}`}</p>
      </div>
    </div>
  );
}

export default Header;
