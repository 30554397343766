import React, { useEffect } from 'react';
import './cta.scss';
import logo from '../../assets/logo.svg';
function Cta({ ctaRef }) {
  useEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0.3) {
            ctaRef.current.style.opacity = '1';
          }
        });
      },
      { threshold: [0, 0.3] }
    );
    observer.observe(ctaRef.current);
  }, [ctaRef]);
  return (
    <section className="cta" ref={ctaRef}>
      <a
        href="https://us-keepexploring.canada.travel/"
        target="_blanc"
        rel="noreferrer"
        aria-label="Visit Destination Canada homepage"
        className="cta__logo"
      >
        <img className="cta__logo" src={logo} alt="" />
      </a>
      <p className="copy">
        From the highest tides to the brightest hearts, iconic mountains to spectacular wildlife,
        there's a Canadian travel itinerary that's right for you.
      </p>

      <a
        href="https://us-keepexploring.canada.travel/"
        rel="noreferrer"
        target="_blanc"
        aria-label="Visit Destination Canada homepage"
        className="carouselButton-link"
      >
        <div className="carouselButton">Find the Right getaway for you</div>
      </a>
    </section>
  );
}

export default Cta;
