import React from 'react';
import './LinksByCategory.scss';
import links from './links.js';
const url = 'https://www.cnn.com/';

function LinksByCategory() {
  return (
    <nav className="links">
      {links.map((link, i) => {
        return (
          <div
            className={
              i === links.length - 1 ? 'links__section links__section-last' : 'links__section'
            }
            key={i}
          >
            {link.url.includes('https') ? (
              <a className="links__section__title" href={link.url}>
                {link.title}
              </a>
            ) : (
              <a className="links__section__title" href={`${url}${link.url}`}>
                {link.title}
              </a>
            )}
            <ul className="links__section__list">
              {link.sublinks.map((sublink, i) => {
                return (
                  <li className="links__section__listItem" key={i}>
                    {sublink.url.includes('https') ? (
                      <a className="links__section__listItem-item" href={sublink.url}>
                        {sublink.title}
                      </a>
                    ) : (
                      <a className="links__section__listItem-item" href={`${url}${sublink.url}`}>
                        {sublink.title}
                      </a>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </nav>
  );
}

export default LinksByCategory;
