export const navItems = [
  {
    href: 'novaScotia',
    copy: 'Nova Scotia',
    copy2: false
  },
  {
    href: 'ontario',
    copy: 'Ontario',
    copy2: false
  },
  {
    href: 'britishColumbia',
    copy: 'British Columbia',
    copy2: false
  },

  {
    href: 'wideOpenCountry',
    copy: 'A Wide Open Country',
    copy2: false
  }
];
