const links = [
  {
    title: 'US',
    url: 'us',
    sublinks: [
      {
        title: 'Crime+Justice',
        url: 'specials/us/crime-and-justice'
      },
      {
        title: 'Energy+Environment',
        url: 'us/energy-and-environment'
      },
      {
        title: 'Extreme Weather',
        url: 'weather'
      },
      {
        title: 'Space+Science',
        url: 'specials/space-science'
      }
    ]
  },
  {
    title: 'World',
    url: 'world',
    sublinks: [
      {
        title: 'Africa',
        url: 'africa'
      },
      {
        title: 'Americas',
        url: 'americas'
      },
      {
        title: 'Asia',
        url: 'asia'
      },
      {
        title: 'Australia',
        url: 'australia'
      },
      {
        title: 'China',
        url: 'china'
      },
      {
        title: 'Europe',
        url: 'europe'
      },
      {
        title: 'India',
        url: 'india'
      },
      {
        title: 'Middle East',
        url: 'middle-east'
      },
      {
        title: 'United Kingdom',
        url: 'uk'
      }
    ]
  },
  {
    title: 'Politics',
    url: 'politics',
    sublinks: [
      {
        title: 'The Biden Presidency',
        url: 'specials/politics/joe-biden-news'
      },
      {
        title: 'Facts First',
        url: 'specials/politics/fact-check-politics'
      },
      {
        title: 'US Elections',
        url: 'election/2020/results/president'
      }
    ]
  },
  {
    title: 'Business',
    url: 'business',
    sublinks: [
      {
        title: 'Markets',
        url: 'https://money.cnn.com/data/markets/'
      },
      {
        title: 'Tech',
        url: 'business/tech'
      },
      {
        title: 'Media',
        url: 'business/media'
      },
      {
        title: 'Success',
        url: 'business/success'
      },
      {
        title: 'Perspectives',
        url: 'business/perspectives'
      },
      {
        title: 'Videos',
        url: 'business/videos'
      }
    ]
  },
  {
    title: 'Opinion',
    url: 'opinions',
    sublinks: [
      {
        title: 'Political Op-Eds',
        url: 'specials/opinion/opinion-politics'
      },
      {
        title: 'Social Commentary',
        url: 'specials/opinion/opinion-social-issues'
      }
    ]
  },
  {
    title: 'Health',
    url: 'health',
    sublinks: [
      {
        title: 'Life,But Better',
        url: 'interactive/life-but-better/'
      },
      {
        title: 'Fitness',
        url: 'specials/health/fitness-life-but-better'
      },
      {
        title: 'Food',
        url: 'specials/health/food-life-but-better'
      },
      {
        title: 'Sleep',
        url: 'specials/health/sleep-life-but-better'
      },
      {
        title: 'Mindfulness',
        url: 'specials/health/mindfulness-life-but-better'
      },
      {
        title: 'Relationships',
        url: 'specials/health/relationships-life-but-better'
      }
    ]
  },
  {
    title: 'Entertainment',
    url: 'entertainment',
    sublinks: [
      {
        title: 'Stars',
        url: 'entertainment/celebrities'
      },
      {
        title: 'Screen',
        url: 'entertainment/movies'
      },
      {
        title: 'Binge',
        url: 'entertainment/tv-shows'
      },
      {
        title: 'Culture',
        url: 'entertainment/culture'
      },
      {
        title: 'Media',
        url: 'business/media'
      }
    ]
  },
  {
    title: 'Tech',
    url: 'business/tech',
    sublinks: [
      {
        title: 'Innovate',
        url: 'specials/tech/innovate'
      },
      {
        title: 'Gadget',
        url: 'specials/tech/gadget'
      },
      {
        title: 'Foreseeable Future',
        url: 'specials/tech/foreseeable-future'
      },
      {
        title: 'Mission:Ahead',
        url: 'specials/tech/mission-ahead'
      },
      {
        title: 'Upstarts',
        url: 'specials/tech/upstarts'
      },
      {
        title: 'Work Transformed',
        url: 'specials/success/work-transformed'
      },
      {
        title: 'Innovative Cities',
        url: 'specials/tech/innovative-cities'
      }
    ]
  },
  {
    title: 'Style',
    url: 'style',
    sublinks: [
      {
        title: 'Arts',
        url: 'style/arts'
      },
      {
        title: 'Design',
        url: 'style/design'
      },
      {
        title: 'Fashion',
        url: 'style/fashion'
      },
      {
        title: 'Architecture',
        url: 'style/architecture'
      },
      {
        title: 'Luxury',
        url: 'style/luxury'
      },
      {
        title: 'Beauty',
        url: 'style/beauty'
      },
      {
        title: 'Video',
        url: 'style/videos'
      }
    ]
  },
  {
    title: 'Travel',
    url: 'travel',
    sublinks: [
      {
        title: 'Destinations',
        url: 'travel/destinations'
      },
      {
        title: 'Food and Drink',
        url: 'travel/food-and-drink'
      },
      {
        title: 'Stay',
        url: 'travel/stay'
      },
      {
        title: 'News',
        url: 'travel/news'
      },
      {
        title: 'Videos',
        url: 'travel/videos'
      }
    ]
  },
  {
    title: 'Sports',
    url: 'https://bleacherreport.com/',
    sublinks: [
      {
        title: 'Pro Football',
        url: 'https://bleacherreport.com/nfl'
      },
      {
        title: 'College Football',
        url: 'https://bleacherreport.com/college-football'
      },
      {
        title: 'Basketball',
        url: 'https://bleacherreport.com/nba'
      },
      {
        title: 'Baseball',
        url: 'https://bleacherreport.com/mlb'
      },
      {
        title: 'Soccer',
        url: 'https://bleacherreport.com/world-football'
      },
      {
        title: 'Olympics',
        url: 'sport/olympics'
      },
      {
        title: 'Hockey',
        url: 'https://bleacherreport.com/nhl'
      }
    ]
  },
  {
    title: 'Videos',
    url: 'videos',
    sublinks: [
      {
        title: 'Live TV',
        url: 'https://go.cnn.com/?stream=cnn'
      },
      {
        title: 'Digital Studies',
        url: 'specials/digital-studios'
      },
      {
        title: 'CNN Films',
        url: 'specials/videos/digital-shorts'
      },
      {
        title: 'HLN',
        url: 'specials/videos/hln'
      },
      {
        title: 'TV Schedule',
        url: 'tv/schedule/cnn'
      },
      {
        title: 'TV Shows A-Z',
        url: 'specials/tv/all-shows'
      },
      {
        title: 'CNNVR',
        url: 'vr'
      }
    ]
  },
  {
    title: 'Audio',
    url: 'audio',
    sublinks: []
  },
  {
    title: 'Coupons',
    url: 'https://coupons.cnn.com/',
    sublinks: [
      {
        title: 'CNN Underscored',
        url: 'cnn-underscored/'
      },
      {
        title: 'Explore',
        url: 'specials/cnn-underscored/explore/'
      },
      {
        title: 'Wellness',
        url: 'specials/cnn-underscored/wellness/'
      },
      {
        title: 'Gadgets',
        url: 'specials/cnn-underscored/gadgets/'
      },
      {
        title: 'Lifestyle',
        url: 'specials/cnn-underscored/lifestyle/'
      },
      {
        title: 'CNN Store',
        url: 'https://store.cnn.com/?utm_source=cnn.com&utm_medium=referral&utm_campaign=navbar'
      }
    ]
  },
  {
    title: 'Weather',
    url: 'weather',
    sublinks: [
      {
        title: 'Climate',
        url: 'specials/world/cnn-climate'
      },
      {
        title: 'Storm Tracker',
        url: 'interactive/storm-tracker/'
      },
      {
        title: 'Wildfire Tracker',
        url: 'interactive/2020/weather/wildfire-and-air-quality-tracker/'
      },
      {
        title: 'Video',
        url: 'specials/weather/weather-video'
      }
    ]
  },
  {
    title: 'More',
    url: 'more',
    sublinks: [
      {
        title: 'Photos',
        url: 'specials/photos'
      },
      {
        title: 'Longform',
        url: 'specials/cnn-longform'
      },
      {
        title: 'Investigations',
        url: 'specials/cnn-investigates'
      },
      {
        title: 'CNN Profiles',
        url: 'specials/profiles'
      },
      {
        title: 'CNN Leadership',
        url: 'specials/more/cnn-leadership'
      },
      {
        title: 'CNN Newsletters',
        url: 'newsletters'
      },
      {
        title: 'Work for CNN',
        url: 'https://warnermediacareers.com/global/en'
      }
    ]
  }
];

export default links;
