//nova scotia
import ns1 from '../../assets/carousel/novaScotia/ns1.png';
import ns2 from '../../assets/carousel/novaScotia/ns2.png';
import ns3 from '../../assets/carousel/novaScotia/ns3.png';
import ns4 from '../../assets/carousel/novaScotia/ns4.png';
import ns5 from '../../assets/carousel/novaScotia/ns5.png';
import ns6 from '../../assets/carousel/novaScotia/ns6.png';
import ns7 from '../../assets/carousel/novaScotia/ns7.png';
import ns8 from '../../assets/carousel/novaScotia/ns8.png';
import ns1D from '../../assets/carousel/novaScotia/ns1D.png';
import ns2D from '../../assets/carousel/novaScotia/ns2D.png';
import ns3D from '../../assets/carousel/novaScotia/ns3D.png';
import ns4D from '../../assets/carousel/novaScotia/ns4D.png';
import ns5D from '../../assets/carousel/novaScotia/ns5D.png';
import ns6D from '../../assets/carousel/novaScotia/ns6D.png';
import ns7D from '../../assets/carousel/novaScotia/ns7D.png';
import ns8D from '../../assets/carousel/novaScotia/ns8D.png';
//ontario
import on1 from '../../assets/carousel/ontario/on1.jpg';
import on2 from '../../assets/carousel/ontario/on2.jpg';
import on3 from '../../assets/carousel/ontario/on3.jpg';
import on4 from '../../assets/carousel/ontario/on4.jpg';
import on5 from '../../assets/carousel/ontario/on5.jpg';
import on6 from '../../assets/carousel/ontario/on6.jpg';
import on7 from '../../assets/carousel/ontario/on7.jpg';
import on8 from '../../assets/carousel/ontario/on8.jpg';
import on1M from '../../assets/carousel/ontario/on1M.jpg';
import on2M from '../../assets/carousel/ontario/on2M.jpg';
import on3M from '../../assets/carousel/ontario/on3M.jpg';
import on4M from '../../assets/carousel/ontario/on4M.jpg';
import on5M from '../../assets/carousel/ontario/on5M.jpg';
import on6M from '../../assets/carousel/ontario/on6M.jpg';
import on7M from '../../assets/carousel/ontario/on7M.jpg';
import on8M from '../../assets/carousel/ontario/on8M.jpg';
//british columbia
import bc1 from '../../assets/carousel/bc/bc1.jpg';
import bc2 from '../../assets/carousel/bc/bc2.jpg';
import bc3 from '../../assets/carousel/bc/bc3.jpg';
import bc4 from '../../assets/carousel/bc/bc4.jpg';
import bc5 from '../../assets/carousel/bc/bc5.jpg';
import bc6 from '../../assets/carousel/bc/bc6.jpg';
import bc7 from '../../assets/carousel/bc/bc7.jpg';
import bc8 from '../../assets/carousel/bc/bc8.jpg';
import bc9 from '../../assets/carousel/bc/bc9.jpg';
import bc1M from '../../assets/carousel/bc/bc1M.jpg';
import bc2M from '../../assets/carousel/bc/bc2M.jpg';
import bc3M from '../../assets/carousel/bc/bc3M.jpg';
import bc4M from '../../assets/carousel/bc/bc4M.jpg';
import bc5M from '../../assets/carousel/bc/bc5M.jpg';
import bc6M from '../../assets/carousel/bc/bc6M.jpg';
import bc7M from '../../assets/carousel/bc/bc7M.jpg';
import bc8M from '../../assets/carousel/bc/bc8M.jpg';
import bc9M from '../../assets/carousel/bc/bc9M.jpg';

export const carouselData = [
  {
    province: 'Nova Scotia',
    contents: [
      {
        photo: ns1,
        photoD: ns1D,
        title: 'Seafood galore',
        copy:
          'From succulent lobster to the world’s largest scallop fishing fleet to fresh-caught-this-morning fish, Nova Scotia is synonymous with seafood. “I expected Nova Scotia to be breathtakingly beautiful and I also expected there to be some incredible seafood,” Kia says. “And everything was incredible.”'
      },
      {
        photo: ns2,
        photoD: ns2D,
        tip: true,
        copy:
          '“We’re a massive country, and things are incredibly spread out. Every province is so different. There’s so much to see, and so many different areas.”',
        name: 'Kyla Welton',
        from: 'from Kenfeld, Nova Scotia'
      },
      {
        photo: ns3,
        photoD: ns3D,
        title: 'Experience a whole new view',
        copy:
          'Nova Scotia’s vibrant coastal cities, historic waterfronts, gorgeous hikes, and scenic seaside drives have a soul-stirring power that begs to be experienced. Unsure where to start? These top attractions take you on a whirlwind tour through Nova Scotia’s history, food, nature, and culture.',
        button: 'Explore more',
        href: 'https://us-keepexploring.canada.travel/things-to-do/top-10-attractions-nova-scotia',
        label: 'Read article The Top 10 Attractions in Nova Scotia'
      },
      {
        photo: ns4,
        photoD: ns4D,
        title: 'soulful sights & sounds',
        copy:
          'With stunning ocean vistas, inviting beaches, charming lighthouses, and jagged cliffs, Nova Scotia’s natural bounty is a feast for the senses. Gorgeous views like the ones from Polly’s Cove on the South Shore or Cape Breton offer a chance to reset and relax.',
        button: 'experience now',
        audio: 'novaScotia-audio',
        audioIndex: 0
      },
      {
        photo: ns5,
        photoD: ns5D,
        tip: true,
        copy:
          '“The South Shore –  that’s where all the beaches are. You’ll find the white sandy beaches. Crystal clear blue water. What gives it away that you’re in Canada is all the spruce trees in the background. Get geared up with a wetsuit and you can take some surfing lessons out there.”',
        name: 'Stéphane Levac',
        from: 'Nova Scotia forager and Indigenous Chef from Port Williams, Nova Scotia'
      },
      {
        photo: ns6,
        photoD: ns6D,
        title: 'Unforgettable experiences',
        copy:
          'Some experiences really are one-of-a-kind: You can’t do them anywhere else. In Nova Scotia, that means tidal bore rafting in the record-setting, river-reversing tides from the Bay of Fundy; dining on the literal ocean floor in Burncoat Head Park, one of North America’s Seven Natural Wonders; and whale watching in an oceanic feeding ground that’s home to 12 species of whales. And that’s just for starters!',
        button: 'learn more',
        href:
          'https://us-keepexploring.canada.travel/things-to-do/nova-scotia-bucket-list-experiences',
        label: 'Read article Nova Scotia Bucket List Experiences'
      },
      {
        photo: ns7,
        photoD: ns7D,
        title: 'maritime culture',
        copy:
          'The Cabot Trail has an established artisan loop that reflects the artistic traditions of the many cultures that have called Nova Scotia home – from the native Mi’kmaq, to the descendants of the l’Acadie French settlers, to the Gaelic-speaking Irish and Scottish immigrants, to Black Loyalists, Jamaican Maroons, New England Planters, and more. “Whether it’s a deep respect for food, or deep respect for nature, or a deep respect for the Mi’kmaq people,” Kia says, “everyone here respects where they live and you can feel that.”'
      },
      {
        photo: ns8,
        photoD: ns8D,
        tip: true,
        copy:
          '“Definitely check out the farmer’s markets. You get a sense of how people actually eat and cook in this region, in terms of the communities and cultures that live in this place. And at what people are looking to do now, and not just in terms of the traditions. It kind of breaks your idea of what this place could be.”',
        name: 'Simon Thibault',
        from: 'Food journalist and writer from Halifax, Nova Scotia'
      }
    ]
  },
  {
    province: 'Ontario',
    contents: [
      {
        photo: on1M,
        photoD: on1,
        title: 'Town and country',
        copy:
          'Sure, Ontario might be home to some of Canada’s biggest cities – Toronto and Ottawa tick all the right urbanite boxes – but the province boasts some of the country’s most stunning natural areas, too. From the jewel-like lakes of Algonquin Provincial Park, to the limestone grottos of Bruce Peninsula National Park, to the biodiverse islets of Thousand Islands National Park, Ontario scratches all the right itches for country mice and city mice alike.'
      },
      {
        photo: on2M,
        photoD: on2,
        tip: true,
        copy:
          '“I love that I can lie in bed at our home in the Rideau Lakes between Ottawa and Kingston, Ontario and hear loons on the lake, geese overhead and coyotes howling in the near-distance. Canada is a nation of rivers (and a river of nations). Unlike the United States, whose frontier was opened by the horse, a domesticated animal, Canada’s western frontier was opened by the birch bark canoe, a domesticated tree. The canoe is still one of the best ways to see the country.”',
        name: 'James Raffan',
        from: 'Explorer and Author, from Rideau Lakes, Ontario'
      },
      {
        photo: on3M,
        photoD: on3,
        title: 'Vibrant Toronto',
        copy:
          'Toronto is one of the world’s most diverse cities, and its unparalleled combination of cultures, cuisines, and creatives make for an unforgettable visit. Get a head start with these stunning attractions – the crème de la crème of Toronto’s many cultural pleasures.',
        button: 'Explore now',
        href:
          'https://us-keepexploring.canada.travel/things-to-do/10-must-have-cultural-experiences-toronto',
        label: 'Read article 10 Must-have cultural experiences in Toronto'
      },
      {
        photo: on4M,
        photoD: on4,
        title: 'The voice of many waters',
        copy:
          'You’re never far from water in Ontario. Wherever you go in the province, rivers, bays, creeks, and lakes form an amicable background soundtrack – and make for delightfully inescapable eye-candy, too.',
        button: 'experience now',
        audio: 'ontario-audio',
        audioIndex: 1
      },
      {
        photo: on5M,
        photoD: on5,
        tip: true,
        copy:
          '“No matter where you go in Canada, you’re on Indigenous lands. I love that this place is home, and that I now live in the same place where my Algonquin ancestors have lived. This place is full of layered histories: Indigenous, non-Indigenous, stories of the natural world and stories of settlement. I love that this place remains a space that so many people love so deeply.”',
        name: 'Christine Luckasavitch',
        from: 'Owner, Waaseyaa Cultural Tours, from Whitney, Ontario'
      },
      {
        photo: on6M,
        photoD: on6,
        title: 'Weekends to remember',
        copy:
          'Got a weekend to spare for an adventure you’ll never forget? Check out these weekend getaways within striking distance of Ontario’s biggest hub cities. From chill to thrill, there’s an itinerary for every flavor and fascination.',
        button: 'learn more',
        href:
          'https://us-keepexploring.canada.travel/things-to-do/6-ontarios-best-weekend-getaways',
        label: "'Read article 6 of Ontario's best weekend getaways"
      },
      {
        photo: on7M,
        photoD: on7,
        title: 'Indigenous culture',
        copy:
          "Many Indigenous Peoples call Ontario home, including the Algonquin, Mississauga, Ojibway, Cree, Odawa, Pottowatomi, Delaware, and the Haudenosaunee (Mohawk, Onondaga, Onoyota'a:ka, Cayuga, Tuscarora, and Seneca). From Indigenous cuisine making headlines in urban foodie scenes to sacred sites and petroglyphs, a visit to Ontario is an opportunity for immersion in cultures ancient and ever new.",
        source: 'Destination Toronto'
      },
      {
        photo: on8M,
        photoD: on8,
        title: 'A country of parks',
        copy:
          'Canada’s vibrant national park system is a globally-renowned treasure. With 46 National Parks and National Park Reserves in Canada’s 13 provinces and territories, there’s something spectacular for every stripe of outdoor enthusiast out there.',
        button: 'go exploring',
        href: 'https://us-keepexploring.canada.travel/things-to-do/canadian-national-parks',
        label: 'Read article One national park to visit in every province and territory'
      }
    ]
  },
  {
    province: 'British Columbia',
    contents: [
      {
        photo: bc1M,
        photoD: bc1,
        title: 'Find your aurora',
        copy:
          "The Northern Lights are one of Mother Nature’s most spectacular shows⁠— and few places in the world have as bounteous a selection of front-row seats as Canada. When the sky opens up above you with painterly greens, blues, and purples, it is a truly surreal moment. Experience it for yourself — you'll be glad you booked a trip to one of the prime viewing spots below.",
        button: 'learn more',
        href:
          'https://us-keepexploring.canada.travel/things-to-do/where-see-northern-lights-canada',
        label: 'Read article, Where to see Northern Lights in Canada'
      },
      {
        photo: bc2M,
        photoD: bc2,
        tip: true,
        copy:
          "“Don’t miss being out on the water in Vancouver, especially in the morning when the sun rises. It's just a beautiful place to be in the morning. We have quite a few harbor seals and otters, mostly around the shoreline. And the odd sea lion will come into the creek. On one occasion, we had four transient Orca whales come into False Creek. And then at the end of June, there are international dragon boat competitions, with people from all over the world racing here. We're lucky to live in such a beautiful city.”",
        name: 'Cameron Gibson',
        from: 'Captain, Aquabus Ferries Vancouver, from Vancouver, BC'
      },
      {
        photo: bc3M,
        photoD: bc3,
        title: 'A spa for all seasons',
        copy:
          'Whether you’re a spa novice or a spa veteran, Canada has an incredible wellness experience for every flavor of relaxation. So shed your cares, loosen up your muscles, get the sauna cranking, and take a plunge – starting with 10 of the nation’s best spas below.',
        button: 'Explore now',
        // imgSrc: 'Oak Bay Beach Hotel, Victoria / Destination British Columbia',
        href: 'https://us-keepexploring.canada.travel/things-to-do/canadas-best-spas',
        label: 'Read article, 10 Must-Visit Spas in Canada'
      },
      {
        photo: bc4M,
        photoD: bc4,
        title: 'City, slopes, and sea',
        copy:
          'Among world-class cities, Vancouver takes its proud position as one of the few places where hitting the beach and the ski slopes in the same day isn’t only possible, it’s seamless. The culture and cuisine on offer is even more gratifying: with Indigenous traditions since time immemorial, the best Chinese food outside of China, and scrumptious seafood all jammed into one exceptional urban package.',
        button: 'get started here',
        href: 'https://us-keepexploring.canada.travel/things-to-do/quick-guide-to-vancouver',
        label: 'Read article, A Quick Guide to Vancouver'
      },
      {
        photo: bc5M,
        photoD: bc5,
        tip: true,
        copy:
          '“I love the lifestyle here, and being surrounded by the mountains. If you’re here in the summer, you have to go mountain biking. If you’re here in the winter, you have to go skiing or snowboarding. When it comes to food, a lot of people would say poutine. For me, I like the pizzas here. And the local beer. You’re going to have fun here. The people are amazing.”',
        name: 'Adrià Flor',
        from: 'Lead Coach and Equipment/Facilities Coordinator, Whistler Adaptive, Whistler, BC'
      },
      {
        photo: bc6M,
        photoD: bc6,
        title: 'Cozy Coastlines',
        copy:
          'Vancouver Island and the surrounding environs are home to thousands of miles of coastline, with beaches galore chock-a-block with wildlife and an incredible diversity of things to see, explore, and do.',
        button: 'experience now',
        audio: 'bc-audio',
        audioIndex: 2
      },
      {
        photo: bc7M,
        photoD: bc7,
        title: 'Seize the day',
        copy:
          'The day trip is a beautiful thing: slip in from home base, maximize your time having fun, get out. With Vancouver, the day trip is elevated to an artform. Not only is the city itself world-class, there’s also world-class cuisine, skiing, hiking, biking, surfing, and more, all within a day’s drive or ferry-ride.',
        button: 'get daytripping',
        href: 'https://us-keepexploring.canada.travel/things-to-do/7-great-day-trips-vancouver',
        label: 'Read article, 7 Great Day Trips Vancouver'
      },
      {
        photo: bc8M,
        photoD: bc8,
        title: 'Dine with the stars',
        copy:
          'Vancouver’s food scene hosted the MICHELIN® Guide for the first time this year, and its diverse culinary offerings were richly rewarded. 8 restaurants earned one star, 12 received Bib Gourmand distinctions, and in all, 60 restaurants were recognized. Dig in below to find out where to, well, dig in.',
        button: 'learn more',
        href: 'https://www.destinationvancouver.com/restaurants/michelin/',
        label: 'Read article, The MICHELIN® Guide Vancouver',
        source: 'Mark Yammine / Kissa Tanto'
      },
      {
        photo: bc9M,
        photoD: bc9,
        title: 'Find your still center',
        copy:
          'British Columbia is a province of wide open spaces in a country of wide open spaces. Here, finding a place to be well and truly still is easy. From coastal rainforests to colorful alpine meadows to cobalt-blue waterways, the province’s many wild areas provide ample opportunity to decompress. Wilderness lodges, like the ones below, are one of the best ways to see BC – and discover a more peaceful pace of life.',
        button: 'explore now',
        href:
          'https://us-keepexploring.canada.travel/things-to-do/finding-stillness-british-columbias-wilderness',
        label: "Read article, Finding Stillness in British Columbia's Wilderness"
      }
    ]
  }
];
