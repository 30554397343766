import React from 'react';
import './Footer.scss';
import LinksByCategory from './Components/LinksByCategory/LinksByCategory';
import SearchBar from './Components/SearchBar/SearchBar';
import LogIn from './Components/LogIn/LogIn';
import TVAudio from './Components/TVAudio/TVAudio';
import Social from './Components/Social/Social';
import Corporate from './Components/Corporate/Corporate';
/**
- [GitHub Repository](https://github.com/turnercode/cnn-courageous-web/tree/main/src/components/component-library/src/components/common-footer)
- Figma: N/A
- [Example pg](https://www.cnn.com/)

### History
- Updated to match cnn.com - 05/2022 - NK
**/
function Footer() {
  return (
    <div className="footer">
      <div className="footer__contents">
        <SearchBar />
        <LogIn screenSize="mobile" />
      </div>
      <hr className="divider divider--m" />
      <div className="footer__contents">
        <TVAudio screenSize="mobile" />
      </div>
      <hr className="divider divider--m" />
      <div className="footer__contents">
        <LinksByCategory />
        <Social />
      </div>
      <div className="footer__contents">
        <hr className="divider divider--d" />

        <Corporate />
      </div>
    </div>
  );
}

export default Footer;
