import React, { useState } from 'react';
import './SearchBar.scss';

function SearchBar() {
  const [q, setQ] = useState('');
  const searchClickHandler = (e) => {
    e.preventDefault();
    window.open(`https://www.cnn.com/search?q=${q}`);
  };

  return (
    <form className="search">
      <input
        className="search__bar"
        autoComplete="off"
        placeholder="Search CNN..."
        onChange={(e) => {
          console.log(e.target.value);
          setQ(e.target.value);
        }}
        value={q}
      ></input>
      <button
        className="search__button"
        onClick={(e) => {
          searchClickHandler(e);
        }}
      >
        <span className="search__button__text">Search</span>
        <span className="search__iconContainer">
          <svg
            className="arrow-right"
            viewBox="0 0 20 20"
            fill="#4D4D4D"
            focusable="false"
            xmlns="https://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path d="M1.53,8.52H14.76L10,3.71a1.5,1.5,0,0,1,2.12-2.12l7.44,7.47a1.49,1.49,0,0,1,0,2.12l-7.44,7.47a1.5,1.5,0,0,1-2.12,0,1.51,1.51,0,0,1,0-2.12l5-5H1.53a1.5,1.5,0,1,1,0-3Z"></path>
          </svg>
        </span>
        <span className="search__iconContainer">
          <svg
            className="search__icon"
            viewBox="0 0 64 64"
            fill="#4D4D4D"
            focusable="false"
            xmlns="https://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path d="M55.3,51.89,42.46,39a19.22,19.22,0,1,0-3.38,3.43L51.9,55.29a2.38,2.38,0,0,0,3.4,0A2.42,2.42,0,0,0,55.3,51.89ZM11.2,27.28a16,16,0,1,1,16,16.07A16.07,16.07,0,0,1,11.2,27.28Z"></path>
          </svg>
        </span>
      </button>
    </form>
  );
}

export default SearchBar;
