import React from 'react';
import './nav.scss';
import logo from '../../assets/logo.svg';
import { navItems } from '../../navItems';

function Nav({ positionMobileMenu, navRef, videoSrc, darkmode }) {
  return (
    <nav className="nav" ref={navRef} aria-label="section navigation">
      <a
        href="https://us-keepexploring.canada.travel/"
        target="_blanc"
        className="nav__logo"
        aria-label="Visit Destination Canada homepage"
      >
        <img className="nav__logo-img" src={logo} alt="" />
      </a>
      {videoSrc === 'large' ? (
        <ul className="nav__links">
          {navItems.map((item, i) => {
            return (
              <li className="nav__item" key={i}>
                <a
                  className={darkmode ? 'nav__item__link nav__item__link-dark' : 'nav__item__link'}
                  href={`#${item.href}`}
                >
                  {item.copy}
                </a>
                <p
                  className={
                    darkmode
                      ? ' nav__item__link--cs nav__item__link--cs-dark'
                      : 'nav__item__link--cs'
                  }
                >
                  {item.copy2 ? 'COMING SOON' : ''}
                </p>
              </li>
            );
          })}
        </ul>
      ) : (
        <button
          className="nav__menu"
          aria-controls="menu"
          aria-label="Open menu"
          onClick={positionMobileMenu}
        >
          <svg
            width="22"
            height="12"
            viewBox="0 0 22 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="nav__menu__icon"
          >
            <path
              className={darkmode ? 'nav__menu__icon-dark' : 'nav__menu__icon-light'}
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.9 6.67071H1.1C0.495 6.67071 0 6.29548 0 5.83687C0 5.37826 0.495 5.00303 1.1 5.00303H20.9C21.505 5.00303 22 5.37826 22 5.83687C22 6.29548 21.505 6.67071 20.9 6.67071ZM20.9 1.66768H1.1C0.495 1.66768 0 1.29245 0 0.833839C0 0.375227 0.495 0 1.1 0H20.9C21.505 0 22 0.375227 22 0.833839C22 1.29245 21.505 1.66768 20.9 1.66768ZM1.1 10.0061H20.9C21.505 10.0061 22 10.3813 22 10.8399C22 11.2985 21.505 11.6737 20.9 11.6737H1.1C0.495 11.6737 0 11.2985 0 10.8399C0 10.3813 0.495 10.0061 1.1 10.0061Z"
            />
          </svg>
        </button>
      )}
    </nav>
  );
}

export default Nav;
