import React from 'react';
import './Corporate.scss';

function Corporate() {
  return (
    <div className="corporate commonWidth">
      <nav className="corporate__nav" data-editable="footerLinks" aria-label="nav links">
        <a href="https://www.cnn.com/terms" className="corporate__nav-link">
          Terms of Use
        </a>
        <a href="https://www.cnn.com/privacy" className="corporate__nav-link">
          Privacy Policy
        </a>
        <a href="https://www.cnn.com/" className="corporate__nav-link">
          Do Not Sell My Personal Information
        </a>
        <a href="https://www.cnn.com/" className="corporate__nav-link">
          Ad Choices
        </a>

        <a href="https://www.cnn.com/about" className="corporate__nav-link">
          About Us
        </a>
        <a href="https://store.cnn.com/" className="corporate__nav-link">
          CNN Store
        </a>
        <a href="https://www.cnn.com/newsletters" className="corporate__nav-link">
          Newsletters
        </a>
        <a href="https://www.cnn.com/transcripts" className="corporate__nav-link">
          Transcripts
        </a>
        <a href="https://collection.cnn.com/" className="corporate__nav-link">
          License Footage
        </a>
        <a href="https://www.cnnnewsource.com/" className="corporate__nav-link">
          CNN Newsource
        </a>
        <a href="https://www.cnn.com/sitemap.html" className="corporate__nav-link">
          Sitemap
        </a>
      </nav>
      <p className="corporate__copyright" data-editable="copyrightText">
        <span className="corporate__copyright-text">© 2022 Cable News Network.</span>{' '}
        <span className="corporate__copyright-text">A Warner Bros. Discovery Company.</span>
        <span className="corporate__copyright-text"> All Rights Reserved.</span> <br /> CNN Sans ™
        &amp; © 2016 Cable News Network.
      </p>
    </div>
  );
}

export default Corporate;
