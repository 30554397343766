import nsMap from '../../assets/episode/nova_scotia_map.svg';
import onMap from '../../assets/episode/ontario_map.svg';
import bcMap from '../../assets/episode/british_columbia_map.svg';
import kia from '../../assets/episode/kia.png';
import trevor from '../../assets/episode/trevor.png';
import wesley from '../../assets/episode/wesley.png';
import nsVideoLoopD from '../../assets/episode/ns_bg_l.mp4';
import nsVideoLoopT from '../../assets/episode/ns_bg_m.mp4';
import nsVideoLoopM from '../../assets/episode/ns_bg_s.mp4';
import onVideoLoopD from '../../assets/episode/on_bg_l.mp4';
import onVideoLoopT from '../../assets/episode/on_bg_m.mp4';
import onVideoLoopM from '../../assets/episode/on_bg_s.mp4';
import bcVideoLoopD from '../../assets/episode/bc_bg_l.mp4';
import bcVideoLoopM from '../../assets/episode/bc_bg_s.mp4';
import bcVideoLoopT from '../../assets/episode/bc_bg_m.mp4';

export const episodeData = [
  {
    id: 'novaScotia',
    episode: 1,
    map: nsMap,
    photo: kia,
    videoD: nsVideoLoopD,
    videoT: nsVideoLoopT,
    videoM: nsVideoLoopM,
    title: 'Nova Scotia',
    link:
      'https://sponsorcontent.cnn.com/interactive/destination-canada/this-is-canada/nova-scotia/',
    label: 'Play Episode One, Explore Nova Scotia with Kia Damon',
    play: true,
    name: 'Kia Damon',
    copy:
      'This award-winning chef explores the Mi’kmaq and Acadian cuisine of Nova Scotia – and discovers the soul-healing power of Canada’s maritime regions, with their windswept beauty and welcoming people.'
  },
  {
    id: 'ontario',
    episode: 2,
    map: onMap,
    photo: trevor,
    title: 'Ontario',
    videoD: onVideoLoopD,
    videoT: onVideoLoopT,
    videoM: onVideoLoopM,
    link: 'https://sponsorcontent.cnn.com/interactive/destination-canada/this-is-canada/ontario/',
    label: 'Play Episode two, Explore Ontario with Trevor Wallace',
    play: true,
    name: 'Trevor Wallace',
    copy:
      'This expedition filmmaker and cultural heritage advocate paddles his way through Ontario’s gorgeous parks and natural waterways to explore the significance of the canoe – and discover the uniquely Canadian heritage and spirit which connects the past to the present.'
  },
  {
    id: 'britishColumbia',
    episode: 3,
    map: bcMap,
    photo: wesley,
    title: 'British Columbia',
    videoD: bcVideoLoopD,
    videoT: bcVideoLoopT,
    videoM: bcVideoLoopM,
    link:
      'https://sponsorcontent.cnn.com/interactive/destination-canada/this-is-canada/british-columbia/',
    label: 'Play Episode three, Explore British Columbia with Wesley Hamilton',
    play: true,
    name: 'Wesley Hamilton',
    copy:
      'This entrepreneur and advocate finds freedom in the wild views and craggy peaks of British Columbia ⁠— and explores the power of decompression, relaxation, and uniquely enriching Canadian experiences.'
  },
  {
    id: 'wideOpenCountry',
    episode: 4,
    title: 'A Wide Open Country',
    copy:
      'Inspired by our films? Get a jumpstart on your own journey up north – and see how Canada has something for everybody.',
    button: 'read article',
    label: 'Read article, Canada: A Wide Open Country',
    link: 'https://sponsorcontent.cnn.com/interactive/destination-canada/wide-open-country/'
  }
];
