import React, { useRef, useState, useEffect } from 'react';
import './carousel.scss';
import sound from '../../assets/carousel/sound.svg';
import mute from '../../assets/carousel/mute.svg';
import nsAudio from '../../assets/carousel/novaScotia/ns_audio.mp3';
import onAudio from '../../assets/carousel/ontario/on_audio.mp3';
import bcAudio from '../../assets/carousel/bc/bc_audio.mp3';
import { carouselData } from './carouselData';
import arrow from '../../assets/carousel/arrow_carousel.svg';

const audios = [new Audio(nsAudio), new Audio(onAudio), new Audio(bcAudio)];

function Carousel({ dataIndex, carouselRef, videoSrc }) {
  const [current, setCurrent] = useState(0);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [playing, setPlaying] = useState(false);
  const container = useRef();
  const photoRef = useRef();
  const currentAudioRef = useRef();

  const data = carouselData[dataIndex];
  const length = data.contents.length - 1;

  const handleClickAudio = (audio) => {
    if (playing) {
      audios[currentAudio].pause();
      if (currentAudio !== audio) {
        setPlaying(true);
        audios[audio].play();
      } else {
        setPlaying(false);
      }
    } else {
      setPlaying(true);
      audios[audio].play();
    }
  };

  const handleClick = (direction) => {
    let currentI;
    container.current.style.animation = 'fade-out 0.4s linear 0s forwards';
    setTimeout(() => {
      container.current.style.animation = 'fade-in 0.4s linear 0s forwards';
      setCurrent(currentI);
    }, 500);

    if (direction === 'right') {
      if (current === length) {
        photoRef.current.style.animation = 'moveTo1R 0.4s linear 0s forwards';
      } else {
        photoRef.current.style.animation = `moveTo${current + 2}R 0.4s linear 0s forwards`;
      }
      currentI = current === length ? 0 : current + 1;
    } else {
      if (current === 0) {
        photoRef.current.style.animation = `moveTo${length + 1} 0.4s linear 0s forwards`;
      } else if (dataIndex === '2' && current === 8) {
        photoRef.current.style.animation = `moveTo8BC 0.4s linear 0s forwards`;
      } else {
        photoRef.current.style.animation = `moveTo${current} 0.4s linear 0s forwards`;
      }

      currentI = current === 0 ? length : current - 1;
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0.3) {
            carouselRef.current.style.opacity = '1';
          }
        });
      },
      { threshold: [0, 0.3] }
    );
    observer.observe(carouselRef.current);
  }, [carouselRef]);

  useEffect(() => {
    if (currentAudio !== null) {
      currentAudioRef.current = audios[currentAudio];
      currentAudioRef.current.addEventListener('ended', () => {
        setPlaying(false);
        currentAudio(null);
      });
    }
  }, [currentAudio]);

  return (
    <section className={`carousel carousel--${dataIndex}`} ref={carouselRef}>
      <h2 className="carouselHead">{data.province}</h2>
      <h3 className="carouselSubhead">See For Yourself</h3>
      <div className="carousel__indicator">
        {data.contents.map((content, i) => {
          return (
            <svg
              key={i}
              className={
                current === i
                  ? 'carousel__indicator__circle carousel__indicator__circle-active'
                  : 'carousel__indicator__circle'
              }
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
            >
              <circle cx="8" cy="8" r="6" />
            </svg>
          );
        })}
      </div>
      <div className="carousel__contents">
        <div className="carousel__contents__arrows">
          <button
            aria-label="Go to previous slide"
            onClick={() => {
              handleClick('left');
            }}
          >
            <img
              src={arrow}
              alt=""
              className={`carousel__contents__arrows__arrow carousel__contents__arrows__arrow-${dataIndex} carousel__contents__arrows__arrow-left`}
            />
          </button>
          <button
            aria-label="Go to next slide"
            onClick={() => {
              handleClick('right');
            }}
          >
            <img
              src={arrow}
              alt=""
              className={`carousel__contents__arrows__arrow carousel__contents__arrows__arrow-${dataIndex}`}
            />
          </button>
        </div>

        <div className="carousel__contents__photoContainer">
          <div
            className={
              dataIndex === '2'
                ? 'carousel__contents__photoContainer__photos carousel__contents__photoContainer__photos--bc'
                : 'carousel__contents__photoContainer__photos'
            }
            ref={photoRef}
          >
            {data.contents.map((content, i) => {
              return (
                <div key={i} className="carousel__contents__photoContainer__photos__imgs">
                  <img
                    className="carousel__contents__photoContainer__photos__img"
                    alt=""
                    src={videoSrc === 'large' ? content.photoD : content.photo}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="carousel__contents__text" ref={container}>
          {data.contents[current].tip ? (
            <>
              <h4
                className={
                  data.province === 'Nova Scotia'
                    ? 'carouselTitle'
                    : 'carouselTitle carouselTitle--ontario'
                }
              >
                Local tip
              </h4>
              <blockquote className={data.province === 'Nova Scotia' ? 'copy' : 'copy localTip'}>
                {data.contents[current].copy}
              </blockquote>
              <p className="carouselName">
                <span className="carouselName--bold">{data.contents[current].name}</span>
                <br />
                {data.contents[current].from}
              </p>
              {/* {data.contents[current].imgSrc && (
                <span className="carouselSource carouselSource--italic">
                  {data.contents[current].imgSrc}
                </span>
              )} */}
            </>
          ) : (
            <>
              <h4 className="carouselTitle">{data.contents[current].title}</h4>
              {data.contents[current].title === 'Seafood galore' ? (
                <p className="copy">
                  From succulent lobster to the world’s largest scallop fishing fleet to
                  fresh-caught-this-morning fish, Nova Scotia is synonymous with seafood. “I
                  expected Nova Scotia to be breathtakingly beautiful and I also expected there to
                  be some incredible seafood,” Kia says. “And everything{' '}
                  <span className="it">was</span> incredible.”
                </p>
              ) : (
                <p className="copy">{data.contents[current].copy}</p>
              )}
              {data.contents[current].source && (
                <span className="carouselSource">
                  ©{' '}
                  <span className="carouselSource carouselSource--italic">
                    {data.contents[current].source}
                  </span>
                </span>
              )}
              {/* {data.contents[current].imgSrc && (
                <span className="carouselSource carouselSource--italic">
                  {data.contents[current].imgSrc}
                </span>
              )} */}

              {data.contents[current].audio ? (
                <button
                  className={
                    data.contents[current].source || data.contents[current].imgSrc
                      ? `carouselButton ${data.contents[current].audio} carousel__button--margin`
                      : `carouselButton ${data.contents[current].audio}`
                  }
                  onClick={() => {
                    handleClickAudio(data.contents[current].audioIndex);
                    setCurrentAudio(data.contents[current].audioIndex);
                  }}
                >
                  {playing && currentAudio === data.contents[current].audioIndex
                    ? 'stop'
                    : data.contents[current].button}{' '}
                  {playing && currentAudio === data.contents[current].audioIndex ? (
                    <img src={mute} alt="" className="muteIcon" />
                  ) : (
                    <img src={sound} alt="" className="soundIcon" />
                  )}
                </button>
              ) : (
                <></>
              )}
              {data.contents[current].href ? (
                <a
                  href={data.contents[current].href}
                  target="_blanc"
                  rel="noreferrer"
                  aria-label={data.contents[current].href}
                >
                  <div
                    className={
                      data.contents[current].source || data.contents[current].imgSrc
                        ? 'carouselButton carousel__button carousel__button--margin'
                        : 'carouselButton carousel__button'
                    }
                  >
                    {data.contents[current].button}
                  </div>
                </a>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
}

export default Carousel;
