import React from 'react';
import './Social.scss';
import TVAudio from '../TVAudio/TVAudio';
import LogIn from '../LogIn/LogIn';

function Social() {
  return (
    <div className="social">
      <a href="https://www.cnn.com/" aria-label="CNN" data-analytics="footer">
        <svg
          className="cnn-badge-icon"
          viewBox="0 0 240 240"
          fill="#CC0000"
          xmlns="https://www.w3.org/2000/svg"
          aria-hidden="true"
        >
          <rect x="0" y="0" width="100%" height="100%" fill="#fff"></rect>
          <path
            d="M.31-.07v240h240V-.07ZM86.54,135.29V92.51c0-16.1,20.81-23.83,31.82-5.12.8,1.35,18.14,31.22,18.14,31.22V92.51c0-16.1,20.8-23.83,31.81-5.12.8,1.35,18.14,31.22,18.14,31.22V76.79h10.38V147a2.11,2.11,0,0,1-1.51,2.14c-1.1.3-2.26-.38-3.11-1.82-.17-.28-9-15.46-17.48-30.13L159.17,90.4c-2.18-3.7-5.43-4.35-7.74-3.72a6.23,6.23,0,0,0-4.55,6.13V147a2.12,2.12,0,0,1-1.51,2.14c-1.11.3-2.27-.38-3.11-1.82-.15-.25-8.34-14.36-16.25-28-10.08-17.38-16.7-28.78-16.8-28.95-2.18-3.71-5.44-4.35-7.77-3.72a6.24,6.24,0,0,0-4.56,6.13v53.05a2.9,2.9,0,0,1-2.72,2.71H67.23a28.64,28.64,0,0,1,0-57.28h14v10.36H67.35a18.28,18.28,0,1,0,0,36.56H83.68A2.84,2.84,0,0,0,86.54,135.29Zm124.77,12.05c0,16.1-20.81,23.83-31.82,5.13-.79-1.35-18.14-31.23-18.14-31.23v26.1c0,16.1-20.8,23.83-31.81,5.13-.8-1.35-18.14-31.23-18.14-31.23v24.61c0,11.17-6.63,17.22-17,17.22H67.25a43.14,43.14,0,0,1,0-86.28h14V87.15h-14a32.78,32.78,0,1,0,0,65.56H94.16a7,7,0,0,0,6.86-6.85V92.81a2.1,2.1,0,0,1,1.51-2.13c1.1-.3,2.26.38,3.11,1.82l16.79,28.93c9.76,16.83,16.17,27.88,16.25,28,2.19,3.7,5.45,4.35,7.78,3.71A6.22,6.22,0,0,0,151,147V92.82a2.11,2.11,0,0,1,1.49-2.14c1.09-.3,2.24.38,3.08,1.82l15.56,26.81,17.49,30.15c1.71,2.9,4.09,3.93,6.16,3.93a6,6,0,0,0,1.61-.22A6.23,6.23,0,0,0,201,147V76.79h10.34Z"
            transform="translate(-0.31 0.07)"
          ></path>
        </svg>
      </a>

      <div className="social__links">
        <TVAudio screenSize="desktop" />
        <div className="social__links__divider" />
        <span className="social__links__copy">FOLLOW CNN</span>
        <div className="social__links__divider" />
        <ul className="social__links__icons">
          <li>
            <a
              href="//facebook.com/CNN"
              target="_blank noreferrer"
              rel="noopener"
              data-icon="facebook"
              aria-label="Facebook page"
              name="Facebook page"
            >
              <svg
                className="social__links__icons-icon"
                viewBox="0 0 64 64"
                fill="rgb(230, 230, 230)"
                xmlns="https://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path d="M56,5.1H8c-1.6,0-3,1.4-3,3v48.8c0,1.7,1.3,3,3,3h25.9V38.7h-7v-8.3h7v-6.1 c0-7.1,4.3-10.9,10.5-10.9c3,0,5.9,0.2,6.7,0.3v7.7h-4.7c-3.4,0-4.1,1.6-4.1,4v5h8.1l-1,8.3h-7v21.2H56c1.6,0,3-1.4,3-3V8.1 C59,6.4,57.7,5.1,56,5.1"></path>
              </svg>
            </a>
          </li>
          <li>
            <a
              href="//twitter.com/CNN"
              target="_blank noreferrer"
              rel="noopener"
              data-icon="twitter"
              aria-label="Twitter feed"
              name="Twitter feed"
            >
              <svg
                className="social__links__icons-icon"
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="rgb(230, 230, 230)"
                xmlns="https://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path d="M60,15.2c-2.1,0.9-4.3,1.5-6.6,1.7c2.4-1.4,4.2-3.6,5.1-6.1c-2.2,1.3-4.7,2.2-7.3,2.7 c-2.1-2.2-5.1-3.5-8.4-3.5c-6.3,0-11.5,5-11.5,11.1c0,0.9,0.1,1.7,0.3,2.5C22,23.2,13.6,18.8,7.9,12c-1,1.6-1.6,3.5-1.6,5.6 c0,3.9,2,7.3,5.1,9.2c-1.9-0.1-3.7-0.6-5.2-1.4v0.1c0,5.4,4,9.9,9.2,10.9c-1,0.3-2,0.4-3,0.4c-0.7,0-1.5-0.1-2.2-0.2 c1.5,4.4,5.7,7.6,10.7,7.7c-3.9,3-8.9,4.8-14.3,4.8c-0.9,0-1.8-0.1-2.7-0.2c5.1,3.2,11.1,5,17.6,5c21.1,0,32.7-16.9,32.7-31.6 c0-0.5,0-1,0-1.4C56.5,19.4,58.5,17.4,60,15.2"></path>
              </svg>
            </a>
          </li>
          <li>
            <a
              href="//instagram.com/cnn"
              target="_blank noreferrer"
              rel="noopener"
              data-icon="instagram"
              aria-label="Instagram feed"
              name="Instagram feed"
              mode="dark"
              color="#0C0C0C"
            >
              <svg
                className="social__links__icons-icon social__links__icons-icon--insta"
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="rgb(230, 230, 230)"
                xmlns="https://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path d="M47,13.2c-1.9,0-3.5,1.6-3.5,3.6s1.6,3.6,3.5,3.6s3.5-1.6,3.5-3.6S49,13.2,47,13.2 L47,13.2z M31.9,23c-5.1,0-9.3,4.3-9.3,9.5s4.2,9.5,9.3,9.5s9.3-4.3,9.3-9.5S37,23,31.9,23L31.9,23z M31.9,46.7 c-7.7,0-14-6.4-14-14.2s6.3-14.2,14-14.2s14,6.4,14,14.2S39.6,46.7,31.9,46.7L31.9,46.7z M15.7,8.8c-3.9,0-7,3.2-7,7.1v33.2 c0,3.9,3.1,7.1,7,7.1h32.7c3.9,0,7-3.2,7-7.1V15.9c0-3.9-3.1-7.1-7-7.1C48.4,8.8,15.7,8.8,15.7,8.8z M48.3,60.9H15.7 C9.2,60.9,4,55.6,4,49.1V15.9C4,9.4,9.2,4.1,15.7,4.1h32.7C54.8,4.1,60,9.4,60,15.9v33.2C60,55.6,54.8,60.9,48.3,60.9L48.3,60.9z"></path>
              </svg>
            </a>
          </li>
        </ul>
        <div className="social__links__divider" />
        <LogIn screenSize="desktop" />
      </div>
    </div>
  );
}

export default Social;
