import React from 'react';
import './TVAudio.scss';

function TVAudio({ screenSize }) {
  return (
    <div
      className={screenSize === 'desktop' ? 'tvAudio tvAudio-desktop' : 'tvAudio tvAudio-mobile'}
    >
      <a
        aria-label="LiveTVLink"
        className="tvAudio__links tvAudio__links-tv"
        href="https://go.cnn.com/?stream=cnn"
        target="_black"
        rel="noreferrer"
      >
        Live TV
      </a>
      <a
        aria-label="AudioLink"
        className="tvAudio__links tvAudio__links-audio"
        href="https://www.cnn.com/audio"
        target="_black"
        rel="noreferrer"
      >
        Audio
      </a>
    </div>
  );
}

export default TVAudio;
